<template>
    <path d="m256 8c-137 0-248 111.1-248 248 0 137 111 248 248 248s248-111 248-248c0-136.9-111-248-248-248zm0 448c-110.5 0-200-89.4-200-200 0-110.5 89.5-200 200-200s200 89.5 200 200-89.4 200-200 200zm0-338c23.2 0 42 18.8 42 42s-18.8 42-42 42-42-18.8-42-42 18.8-42 42-42zm56 254c0 6.6-5.4 12-12 12h-88c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h12v-64h-12c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h64c6.6 0 12 5.4 12 12v100h12c6.6 0 12 5.4 12 12v24z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 512, height: 512 });
    },
    emits: ['dimensions'],
};
</script>
